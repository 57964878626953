import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import './App.css';

import type {
  MRT_ColumnDef,
  MRT_PaginationState
} from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';



function App() {

  const [rowSelection, setRowSelection] = useState({})


  const fetchDate = async (index : number, size : number) => {
    const response   = await fetch(`https://zyluxmedia.mainstarr.com/retrieve4343/${index}/${size}`)
    if (!response.ok) {
        console.log("Error")
        return
    }
    const check = await response.json()
    console.log("Check", check)
    setTableData(check)
  }
  
  useEffect(() => {  
    fetchDate(0, 20)
  }, [])
  
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20
  })
  
  // const [isRefetching, setIsRefetching] = useState(false)
  //const { setPaginationData, getPaginationData } = useTablePagination()
  const [tableData, setTableData] = useState({
    data : [],
    totalCount : 0
  })
  
  useEffect(() => {  
      fetchDate(pagination.pageIndex, pagination.pageSize)
  }, [pagination.pageIndex, pagination.pageSize])
  
  
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'publisher', //access nested data with dot notation
        header: 'Publisher',
        size: 15
      },
      {
        accessorKey: 'offer', //access nested data with dot notation
        header: 'Offer',
        size: 15
      },
      {
        accessorKey: 'clickIP', //access nested data with dot notation
        header: 'IP',
        size: 15
      },
      {
        accessorKey: 'clickTime', //access nested data with dot notation
        header: 'Click Time',
        size: 15
      },
      {
        accessorKey: 'clickTime', //access nested data with dot notation
        header: 'Click Time',
        size: 15
      },
      {
        accessorKey: 'status', //access nested data with dot notation
        header: 'Status',
        size: 15,
        Cell: ({ cell } : any) => (
          <span>
            {cell.getValue() === "Approved" ? <span style={{color:'green'}}>{cell.getValue()}</span> : <span style={{color:'red'}}>{cell.getValue()}</span>}
          </span>
        )
      },
      {
        accessorKey: 'Reason', //access nested data with dot notation
        header: 'Reason',
        size: 15,
        // Cell: ({ cell } : any) => (
        //   <span>
        //     {cell.getValue() === "Approved" ? <span style={{color:'green'}}>{cell.getValue()}</span> : <span style={{color:'red'}}>{cell.getValue()}</span>}
        //   </span>
        // )
      },
      {
        accessorKey: 'broswerName', //access nested data with dot notation
        header: 'Browser',
        size: 15
      },
      {
        accessorKey: 'country', //access nested data with dot notation
        header: 'Country',
        size: 15
      },
      {
        accessorKey: 'deviceType', //access nested data with dot notation
        header: 'Device',
        size: 15
      },
      {
        accessorKey: 'osName', //access nested data with dot notation
        header: 'OS Name',
        size: 15
      },
      {
        accessorKey: 'createdAt', //access nested data with dot notation
        header: 'Created At',
        size: 15
      },
    ],
    []
  )
  
  const table = useMaterialReactTable({
    columns,
    data: tableData?.data || [],
    manualPagination: true,
    rowCount: tableData?.totalCount || 0, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableRowSelection: false,
    onRowSelectionChange: setRowSelection,
    initialState: { pagination: { pageSize: 20, pageIndex: 0 } },
    onPaginationChange: setPagination,
    paginationDisplayMode: 'pages',
    state: { rowSelection, pagination, 
      // showProgressBars: isRefetching 
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        {/* {selectedRows.length === 0 ? null : (
          <Button
            onClick={() => {
              setModalOpen(true)
            }}
          >
            Send Email Camapaign
          </Button>
        )} */}
        {/* <select onChange={handleChange} className='border-solid border-purple-400'>
          {select.network.map((item, index: number) => {
            return (
              <option key={index} value={item.id}>
                {item.value}
              </option>
            )
          })}
        </select> */}
      </>
    )
  })



  return (
    <div className="container">
     
        <div className=''><MaterialReactTable table={table} /></div>
      
    
    </div>
  );
}

export default App;
